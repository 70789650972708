<template>
<div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>财务中心</template>
        <template v-slot:secondMenu>对账管理</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
          对账详情
      </div>
      <page-box >

        <div class="accounts">
          <div>
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-row >
              <el-form-item label="购液企业名称：">
                <span>{{formInline.name}}</span>
              </el-form-item>
              </el-row>
              <el-row >
              <el-form-item label="计划日期时间：">
                <el-date-picker v-model="formInline.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 400px" value-format="yyyy-MM-dd" @change="onSubmit"></el-date-picker>
              </el-form-item>
              </el-row>
              <el-row >
              <el-form-item label="对账单标题：">
                <el-input v-model="formInline.title" placeholder="请输入内容" ></el-input>
              </el-form-item>
              </el-row>
            </el-form>
          </div>
          <div>
            <reconciliation-modal :list='planList' :formInline="formInline"/>
          </div>
        </div>


        <div slot="box" class="issued-manage" style="marginTop: 20px">
          <!-- 数据表 -->
          
          <el-table
            border
            
            :data="planList"
            class="issued-list"
            style="width: 100%"
            :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}"
            :cell-style="{color: '#0a0b1a'}"
          >
            <el-table-column width="50" align="center">
              <div slot="header" slot-scope="scope">
                <el-checkbox
                  v-model="globelCheckedAll"
                  @change="clickCheckAll(scope)"
                  :indeterminate="isIndeterminateAll"
                ></el-checkbox>
              </div>
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.isChecked"
                  :indeterminate="scope.row.isIndeterminate"
                  @change="handleSelectionChange(scope.row)"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column align="center" type="expand">
              <template slot-scope="props">
                <!-- 展开内容表 -->
                <el-table
                  
                  :data="props.row.orders"
                  style="width: 100%"
                  class="child-table"
                  
                >
                  <el-table-column  width="120" align="center">
                    <template slot-scope="scope">
                      <el-checkbox
                      v-model="scope.row.isChecked"
                        @change="clickCheckItemFn(props.row, scope.row)"
                      ></el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column label="订单号">
                  <template slot-scope="scope">{{ scope.row.orderNumber }}</template>
                </el-table-column>
                <el-table-column prop="distributionType" label="配送方式" >
                  <template slot-scope="scope">
                    <span>{{scope.row.distributionType==1?"自提":scope.row.distributionType==2?"配送":""}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="tractorNumber" label="车辆信息" >
                  <template slot-scope="scope">
                    <span>{{scope.row.tractorNumber?scope.row.tractorNumber.slice(0,-5)+'/'+scope.row.trailerNumber.slice(0,-5):"--"}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="lngSourceName" label="液源" ></el-table-column>
                <el-table-column prop="listingPrice" label="结算单价（元/吨）" ></el-table-column>
                <el-table-column label="装车量（吨）" >
                  <template slot-scope="scope">{{ scope.row.orderBill?scope.row.orderBill.shipmentWeight:0 }}</template>
                </el-table-column>
                <el-table-column label="卸车量（吨）" >
                  <template slot-scope="scope">{{scope.row.orderBill?scope.row.orderBill.unloadWeight:0 }}</template>
                </el-table-column>
                <el-table-column label="结算量（吨）" >
                  <template slot-scope="scope">{{ scope.row.orderBill?scope.row.orderBill.weight:0 }}</template>
                </el-table-column>
                <el-table-column label="结算总金额（元）" >
                  <template slot-scope="scope">{{ scope.row.orderBill?scope.row.orderBill.totalAmount:0 }}</template>
                </el-table-column>
                <el-table-column prop="amount" label="进款金额(元)" ></el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="计划日期">
            <template slot-scope="scope">
                {{scope.row.planDate}}
              </template>
          </el-table-column>
          <el-table-column prop="countCar" label="结算装车数" ></el-table-column>
          <el-table-column prop="packWeight" label="装车量（吨）" ></el-table-column>
          <el-table-column prop="settlementAmount" label="结算量（吨）" ></el-table-column>
          <el-table-column prop="settlementVolume" label="结算总金额（元）" ></el-table-column>
          </el-table>

        </div>
      </page-box>
      <div style="float: right;">
            <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryInfo.pageNo"
                  :page-sizes="[10, 20, 30, 50]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
          </el-pagination>
      </div>
    </el-card>
</div>
</template>
<script>
  import BreadcrumbNav from "../../../common/BreadcrumbNav";
  import { getReconciledRequest,getEditReconciledRequest } from 'network/financial'
  import ReconciliationModal from './ReconciliationModal.vue';
  
export default {
  name: "TradeDetails",
  components: {
      BreadcrumbNav,
      ReconciliationModal
    },
    props: { },
  data() {
    return {
      isIndeterminateAll: false,
      globelCheckedAll: false,
      queryInfo: {
          pageNo: 1,
          pageSize: 10
        },
      total: 0,
      planList:[],
      formInline: {
          name:'',
          title: '',
          time: '',
          stationId: '',
          banlance: ''
        },
    }
  },
  mounted() {
    
  },
  created() {
      this.getList()
    },
  methods: {
    onSubmit(){
      this.getList(this.formInline.time)
    },
    getList(time){
      console.log(!time);
      this.formInline = this.$route.query.scope
      this.formInline.stationId = this.$route.query.scope.stationId
      
      getReconciledRequest({
        startTime:!time?this.$route.query.scope.time[0]:time[0],
        endTime: !time?this.$route.query.scope.time[1]:time[1],
        page: this.queryInfo,
        stationId: this.$route.query.scope.stationId
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          // this.handlerDatas(result.data.list)
          let number = result.data.list==null?0:result.data.list.length
          for(let i = 0;i<number;i++){
            result.data.list[i].isChecked = false
            result.data.list[i].isIndeterminate = false
            for(let y = 0;y<result.data.list[i].orders.length;y++){
              result.data.list[i].orders[y].isChecked = false
              result.data.list[i].orders[y].isIndeterminate = false
            }
          }
          console.log(result.data.list);
          this.planList = result.data.list
          this.total = result.data.total;
      })
    },
//     handlerDatas(arr){
//       console.log(arr);
//   // arr 传过来的原数组  
//   let tempArr = [];
//   let endData = [];
//   for (let i = 0; i < arr.length; i++) {
//     if (tempArr.indexOf(arr[i].planDate) === -1) {
//       endData.push({
//         planDate: arr[i].planDate,
//         isChecked: false,
//         isIndeterminate: false,
//         children: [arr[i]]
//       });
//       tempArr.push(arr[i].planDate);
//     } else {
//       for (let j = 0; j < endData.length; j++) {
//         if (endData[j].planDate == arr[i].planDate) {
//           endData[j].children.push(arr[i]);
//           break;
//         }
//       }
//     }
//   }
//   for(let v=0;v<endData.length;v++){
//     endData[v].carNumber= endData[v].children.length
//     for(let i = 0;i<endData[v].children.length;i++){
//       endData[v].children[i].isChecked=false
//       // endData[v].children[i].isIndeterminate=false
//     }
//     // endData[v].weight= endData[v].children.reduce((sum, e) => sum + Number(e.orderBill.shipmentWeight || 0), 0)
//     // endData[v].price = endData[v].children.reduce((sum, e) => sum + Number(e.price || 0), 0)
//     // endData[v].totalAmount = endData[v].children.reduce((sum, e) => sum + Number(e.orderBill.totalAmount || 0), 0)
//   }
//   console.log(endData);
//   this.planList = endData
// },

    // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNo = 1
        this.queryInfo.pageSize = newSize;
        this.getList(this.formInline.time)
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNo = newPage;
        this.getList(this.formInline.time)
      },
    updateRoleList() {
        this.$emit('plan-list');
      },
    // 手动更改全选按钮的状态
    updateCheckedAllBtnStatus(value) {
      
      // 如果是选了勾选
      if (value) {
        // 检查是否所有数据都手动勾选了
        let isAllChecked = this.planList.every(v => v.isChecked)
        if (isAllChecked) {
          this.globelCheckedAll = true
          this.isIndeterminateAll = false
        } else {
          this.isIndeterminateAll = true
        }
      } else {
        // 检查是否所有数据取消勾选了
        let isAllCancelChecked = this.planList.every(v => v.isChecked == false)
        if (isAllCancelChecked) {
          this.globelCheckedAll = false
          this.isIndeterminateAll = false
        } else {
          this.isIndeterminateAll = true
        }
      }
    },
    // 全选所有
    clickCheckAll(item) {
      // 此处console的item无效，仅为了eslint校验，而slot-scope="scope"又是必传
      this.planList = this.planList.map(val => {
        console.log(val);
        val.isChecked = this.globelCheckedAll
        val.isIndeterminate = false
        val.orders = val.orders.map(i => {
          i.isChecked = this.globelCheckedAll
          return i
        })
        return val
      })
      this.updateCheckedAllBtnStatus(this.globelCheckedAll)
    },
    // 每行选择事件
    handleSelectionChange(val) {
      console.log(val,4855);
      val.orders = val.orders.map(i => {
        i.isChecked = val.isChecked
        return i
      })
      val.isIndeterminate = false
      this.updateCheckedAllBtnStatus(val.isChecked)
    },

    // 每个小项选择事件-单选
    clickCheckItemFn(row, item) {
      console.log(row,item);
      // 如果是选了勾选
      if (item.isChecked) {
        this.isIndeterminateAll = true
        // 检查是否所有数据都手动勾选了
        let isAllChecked = row.orders.every(v => v.isChecked)
        if (isAllChecked) {
          this.isIndeterminateAll = false
          row.isChecked = true
          row.isIndeterminate = false
        } else {
          row.isIndeterminate = true
        }
      } else {
        // 检查是否所有数据取消勾选了
        let isAllCancelChecked = row.orders.every(v => v.isChecked == false)
        if (isAllCancelChecked) {
          row.isChecked = false
          row.isIndeterminate = false
          this.isIndeterminateAll = true
        } else {
          row.isIndeterminate = true
        }
      }
    },
  }
}
</script>

<style scoped>
.accounts{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>


